<template>
  <div>
    <v-container fluid>
      <v-row class="pa-2">
        <v-col class="pa-2">
          <h2 class="logo-title">ShairMail</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="mt-10" fluid>
      <v-row>
        <v-col>
          <v-card
            :disabled="isLoading"
            :loading="isLoading"
            class="mx-auto pa-8 auth-card"
            max-width="410"
          >
            <v-icon x-large class="mt-8 teal--text mb-5"> mdi-mail </v-icon>
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters.authStatus == "loading" ? true : false;
      // return this.$store.state.authModule.status == "loading" ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-title {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.auth-card {
  min-height: 500px;
  max-height: 90vh;
}
</style>